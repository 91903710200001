import styled from 'styled-components'

import { StyledMenuButton } from '../dashboard/Header/styles'

export const StyledSiteHeader = styled.header`
  position: relative;

  padding: 2rem 0;

  background-color: white;
  box-shadow: 0 1px 3px hsla(0, 0%, 0%, 0.1), 0 2px 6px hsla(0, 0%, 0%, 0.2);

  @media (prefers-color-scheme: dark) {
    color: white;
    background-color: ${props => props.theme.colors.primary.dark};
  }

  .container {
    display: flex;
    align-items: center;
  }

  ${StyledMenuButton} {
    background-color: hsl(0 0% 0% / 0.0625);

    @media (prefers-color-scheme: dark) {
      background-color: hsl(0 0% 100% / 0.125);
    }

    &:hover {
      background-color: hsl(0 0% 0% / 0.125);

      @media (prefers-color-scheme: dark) {
        background-color: hsl(0 0% 100% / 0.25);
      }
    }
  }

  nav {
    margin-left: auto;

    @media (max-width: 28rem) {
      display: none;
    }
  }

  ul {
    display: flex;

    list-style: none;

    li + li {
      margin-left: 0.5rem;
    }
  }
`
