import type { FormEvent } from 'react'

import {
  DndContext,
  useSensor,
  useSensors,
  PointerSensor,
  KeyboardSensor,
  closestCenter,
  DragEndEvent,
} from '@dnd-kit/core'
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers'
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable'

import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'

import { IExtraInformation } from '../../../declarations/interfaces'

import ContactPerson from './ContactPerson'
// import {
//   AnyEventObject,
//   BaseActionObject,
//   ResolveTypegenMeta,
//   ServiceMap,
//   State,
//   TypegenDisabled,
// } from 'xstate'
// import LoadingIndicator from '../../LoadingIndicator'

import formatPhoneNumber from 'swedish-phone-number-formatter'
import { ErrorMessage } from './ErrorMessage'
// import PasswordPrompt from './PasswordPrompt'
// import {
//   InfoEvent,
//   InfoMachineContext,
//   InfoTypestate,
// } from '../../../views/Upload/machines/extra.machine'

const StyledForm = styled.form`
  margin-top: 2rem;

  input[pattern]:not(:placeholder-shown):not(:focus):invalid {
    border-color: red;
  }
`

interface ExtraFormProps {
  extra: IExtraInformation | string | null
  setExtra(newExtra: IExtraInformation): void
  // onEncrypt(password: string): Promise<boolean>
  // onDecrypt(password: string): Promise<boolean>
  extraError: string | null
  clearExtraInfo(): void

  // state: State<
  //   InfoMachineContext,
  //   InfoEvent,
  //   InfoTypestate,
  //   {
  //     // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //     value: any
  //     context: InfoMachineContext
  //   },
  //   ResolveTypegenMeta<
  //     TypegenDisabled,
  //     AnyEventObject,
  //     BaseActionObject,
  //     ServiceMap
  //   >
  // >
}

export default function ExtraForm({
  extra,
  setExtra,
  // onEncrypt,
  // onDecrypt,
  extraError,
}: // state,
ExtraFormProps) {
  const needsDecryption = typeof extra === 'string'
  const isDecrypted = typeof extra === 'object'

  const contactMan =
    typeof extra === 'object'
      ? extra?.contacts?.[
          // (extra?.contactStart - 1 + programmeMonth_Date.getMonth()) %
          (extra?.contactStart - 1 + new Date().getMonth()) %
            extra?.contacts.length
        ]
      : null

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  function addContact(
    ev: FormEvent<
      {
        readonly elements: {
          name: HTMLInputElement
          phone: HTMLInputElement
        } & HTMLFormControlsCollection
      } & HTMLFormElement
    >,
  ) {
    ev.preventDefault()

    if (needsDecryption) {
      return false
    }

    const name = ev.currentTarget.elements.name
    const phone = ev.currentTarget.elements.phone

    const formattedPhone = formatPhoneNumber(phone.value)

    if (name?.value.length > 0 && phone?.value.length > 0) {
      const newContacts = Array.from(extra.contacts)
      newContacts.push({
        name: name.value,
        phone: formattedPhone,
        id: uuidv4(),
      })

      setExtra({
        ...extra,
        contacts: newContacts,
      })

      name.value = ''
      phone.value = ''
      name.focus()
    }
  }

  function onContactDragEnd(event: DragEndEvent) {
    if (needsDecryption) {
      return false
    }

    const { active, over } = event

    if (active.id !== over.id) {
      const oldIndex = extra.contacts.findIndex(
        contact => contact.id === active.id,
      )
      const newIndex = extra.contacts.findIndex(
        contact => contact.id === over.id,
      )

      const newContacts = arrayMove(extra.contacts, oldIndex, newIndex)

      setExtra({
        ...extra,
        contacts: newContacts,
      })
    }
  }

  function removeContact(contactId: string) {
    if (needsDecryption) {
      return false
    }

    const newContacts = extra.contacts.filter(c => c.id !== contactId)

    setExtra({
      ...extra,
      contacts: newContacts,
    })
  }

  function createDataStructure() {
    setExtra({
      contacts: [],
      contactStart: 0,
    })
  }

  return (
    <section className='form extra-form'>
      <h1>Extra information</h1>

      {!Boolean(extra) ? <p>Det finns ingen extra information.</p> : null}

      {/* {state.value === 'failure' ? (
        <>
          <p>failure</p>
        </>
      ) : null} */}
      {extraError ? (
        <ErrorMessage>
          <p>{extraError}</p>
        </ErrorMessage>
      ) : null}

      {/* {state.value === 'idle' ? <p>idle</p> : null} */}

      {/* {state.value === 'loading' ? (
        <>
          <p>loading</p>
          <LoadingIndicator text='Hämtar information…' />
        </>
      ) : null} */}

      {/* <PasswordPrompt
        stateValue={state.value.toString()}
        hasData={Boolean(extra)}
        onDecrypt={onDecrypt}
        onEncrypt={onEncrypt}
        createDataStructure={createDataStructure}
      /> */}

      {!extra && createDataStructure ? (
        <button
          type='button'
          className='btn btn-primary'
          onClick={() => createDataStructure()}
        >
          Skapa extrainformation
        </button>
      ) : null}

      {/* {state.value === 'decrypting' && <LoadingIndicator text='Dekrypterar…' />} */}

      {/* {[
        'decrypted',
        'encrypted',
        'modified',
        'decrypting',
        'encrypting',
      ].includes(state.value.toString()) ? (
        <> */}
      {/* {state.value === 'decrypting' && <p>decrypting</p>}
          {state.value === 'decrypted' && <p>decrypted</p>}
          {state.value === 'encrypting' && <p>encrypting</p>}
          {state.value === 'encrypted' && <p>encrypted</p>}
          {state.value === 'modified' && <p>modified</p>} */}
      {extra && isDecrypted ? (
        <>
          <div className='grid'>
            <div>
              <h2>Kontaktmän för talare</h2>
              {extra.contacts.length > 0 ? (
                <div>
                  <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    modifiers={[
                      restrictToVerticalAxis,
                      restrictToParentElement,
                    ]}
                    onDragEnd={onContactDragEnd}
                  >
                    <SortableContext
                      items={extra.contacts.map(contact => contact.id)}
                      strategy={verticalListSortingStrategy}
                    >
                      {extra.contacts.map(contact => {
                        return (
                          <ContactPerson
                            key={contact.id}
                            contact={contact}
                            editContact={() => {
                              return null
                            }}
                            removeContact={() => removeContact(contact.id)}
                          />
                        )
                      })}
                    </SortableContext>
                  </DndContext>
                </div>
              ) : (
                <p>Det finns inga kontaktmän tillagda.</p>
              )}
              <StyledForm onSubmit={addContact}>
                <footer className='form-footer'>
                  <h3>Lägg till en kontaktman</h3>
                  <label htmlFor='name'>Kontaktmannens namn</label>
                  <input
                    type='text'
                    id='name'
                    name='name'
                    placeholder='Ange ett namn…'
                    required
                  />
                  <label htmlFor='phone'>Telefonnummer</label>
                  <input
                    type='text'
                    id='phone'
                    name='phone'
                    placeholder='Ange ett telefonnummer…'
                    required
                    pattern='[0-9\- ]{10,}'
                  />
                  <div className='buttons'>
                    <button type='submit' className='btn btn-primary'>
                      Lägg till
                    </button>
                  </div>
                </footer>
              </StyledForm>
            </div>
            <div>
              <label htmlFor='contact-start' style={{ marginTop: '3rem' }}>
                Kontaktperson första månaden på året
              </label>
              <select
                id='contact-start'
                name='contact-start'
                value={extra.contactStart}
                onChange={ev => {
                  setExtra({
                    ...extra,
                    contactStart: Number(ev.target.value),
                  })
                }}
              >
                <option value={0} disabled>
                  - Ingen kontaktman vald -
                </option>
                {extra.contacts.map((contact, contactIndex) => (
                  <option key={contact.id} value={contactIndex + 1}>
                    {contact.name}
                  </option>
                ))}
              </select>
              {contactMan && 'name' in contactMan ? (
                <small className='caption help-text'>
                  Just nu skulle {contactMan.name} vara kontaktperson
                </small>
              ) : null}
            </div>
          </div>
          {/* <PasswordPrompt
            stateValue={state.value.toString()}
            hasData={Boolean(extra)}
            onDecrypt={onDecrypt}
            onEncrypt={onEncrypt}
            createDataStructure={createDataStructure}
          /> */}
        </>
      ) : null}
      {/* </>
      ) : null} */}

      {/* {state.value === 'encrypting' && <LoadingIndicator text='Krypterar…' />} */}

      {/* {state.value === 'uploading' && (
        <LoadingIndicator text='Laddar upp informationen…' />
      )} */}
    </section>
  )
}
