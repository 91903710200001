
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { useState, useEffect } from 'react'
import type { FormEvent } from 'react'
import { useRouter } from 'next/router'
import Head from 'next/head'

import useTranslation from 'next-translate/useTranslation'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useAuthGuard } from '../contexts/auth'

import { useMessages } from '../contexts/messages'

import SiteHeader from '../components/SiteHeader'
import { Menu } from '../components'
import {
  MenuList,
  MenuListFooter,
  MenuListItem,
} from '../components/Menu/styles'
import NavLink from '../components/NavLink'

import styled from 'styled-components'
import SiteLogo from '../components/SiteLogo'
import { Protip } from '../views/Upload/styles'
import Link from 'next/link'
import { toggleMenu } from '../components/Menu'
import { ErrorMessage } from '../components/upload/ExtraForm'
import type { AuthApiError } from '@supabase/supabase-js'
import { apiAxios, manadsbladetAPI } from '../utils/API'

export const LoginForm = styled.main`
  width: 100%;
  max-width: 20rem;

  padding: 2rem;
  margin: 1rem auto;

  background-color: white;

  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 0.25rem #0003;

  @media (prefers-color-scheme: dark) {
    color: white;
    background-color: #1c1c1c;
  }

  /* h1,
  h2 {
    text-align: center;
  } */

  h1 {
    margin-top: 2rem;
    margin-bottom: 2rem;

    font-size: 2rem;
  }

  /* h2 {
    margin: 0.5rem 0 2rem;

    font-weight: 600;
  } */

  label {
    display: block;

    width: 100%;

    margin-bottom: 0.25rem;

    font-size: 0.9rem;
    font-weight: 600;
  }

  .input-group {
    margin-bottom: 1rem;
  }

  .input-group + button {
    margin-top: 0.5rem;
  }

  .spinner {
    margin: 4rem 0 2rem;

    font-size: 4rem;
    text-align: center;

    color: ${props => props.theme.colors.primary.brand};
  }
`

interface FormElements extends HTMLFormControlsCollection {
  username: HTMLInputElement
  password: HTMLInputElement
}

interface LoginFormElement extends HTMLFormElement {
  readonly elements: FormElements
}

export default function LoginPage() {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<{ message: string; code?: string } | null>(
    null,
  )

  const { MessagesToRender } = useMessages()

  const { t } = useTranslation('login')

  const router = useRouter()

  const {
    /* user, token, cookieUpdatedAt */ state,
    send,
    supabaseClient: supabase,
    user,
    // session,
    token,
  } = useAuthGuard((router.query.next as string) ?? '/')

  // const { user, token } = state.context

  useEffect(() => {
    toggleMenu(false)
  }, [router])

  const destination = {
    '/programme': undefined,
    '/upload': 'Kontrollpanel',
    '/bo': 'Administratörspanel',
  }[router.query.next as string]

  // useEffect(() => {
  //   // ! If logging out in another tab, the user will still be logged in, in this tab, but getting the token result will fail and will just show an infinite spinner.
  //   if (user && cookieUpdatedAt > 0) {
  //     // ? Maybe only refresh if it will soon expire?
  //     console.log('Refreshing Supabase token…')

  //     setLoading(true)

  //     // Refresh the token (make it "warm") and redirect
  //     user
  //       .getIdTokenResult()
  //       .then(() => {
  //         const next = router.query.next as string

  //         if (next) {
  //           router.push(next)
  //         } else {
  //           router.push('/')
  //         }
  //       })
  //       .catch(err => {
  //         console.error(
  //           'Something went wrong when getting id token result',
  //           err,
  //         )
  //       })
  //   }
  // }, [user, router.query.next, cookieUpdatedAt]) // router must be excluded here, to prevent infinite loop of updating state. Change this logix somhow

  async function login(ev: FormEvent<LoginFormElement>) {
    try {
      ev.preventDefault()

      // Must assign it to a variable, because if the user is not signed in,
      // calling the auth.getUser() method will throw an error,
      // which makes the ev.currentTarget object not available.
      const formTarget = ev.currentTarget

      if (
        !formTarget.elements.username.value.length ||
        !formTarget.elements.password.value.length
      ) {
        setError({ message: 'Användarnamn eller lösenord saknas' })
        return
      }

      setLoading(true)
      setError(null)

      if (user !== null) {
        /**
         * Before signing in, make sure to sign out the currently signed in user.
         * ? Will this refresh the page, since the `user` object changes?
         * ? If so, the login credentials in the form will be lost!
         */
        const { error } = await supabase.auth.signOut()

        if (error) {
          console.error(
            'Failed to sign out via Supabase on /login after login submission',
            error,
          )
        }
      }

      /**
       * The congregation's user name, usually a city name.
       * In this case it is the prefix of an e-mail address.
       *
       * Removed whitespace from username, to form a valid e-mail address.
       * Now and then Sentry logs errors about this, likely due to autocomplete,
       * since the username is actually a name of a city.
       *
       * TL;DR: Some phones add a space after the name, and capitalizes the first letter.
       * Good in a sentence, bad in an e-mail address.
       */
      const username = formTarget.elements.username.value.replace(/\s/g, '')
      const password = formTarget.elements.password.value

      const isEmail = username.includes('@')
      const email = isEmail ? username : username + '@manadsbladet.se'

      const {
        data: { session },
        error: signInError,
      } = await supabase.auth.signInWithPassword({
        email,
        password,
      })

      if (signInError) {
        throw signInError
      }

      if (!apiAxios.defaults.headers.common.Authorization) {
        if (session?.access_token) {
          manadsbladetAPI.addAuthTokenToHeaders(session.access_token)
        }
      }

      // Send the password to the /api/token endpoint to store an RSA encrypted PBKDF2-HMAC-SHA256 in a secure session cookie.
      // This secret is later used on the server to decrypt the user's encryption key.
      // By storing it in a session cookie, the user does not need to enter the password again, until the session expires, for the encryption/decryption to work on the server.
      const tokenResponse = await apiAxios.post(
        '/token',
        {
          secret: password,
        },
        {
          withCredentials: true,
        },
      )
      console.log('Token response', tokenResponse, tokenResponse.status)

      if (state.value === 'unauthenticated') {
        console.log('Sending SIGN_IN event to auth machine')
        send('SIGN_IN', {
          data: {
            session,
            user: {
              app_metadata: user?.app_metadata,
              email: user?.email,
            },
            secret: password,
          },
        })
      }
    } catch (err) {
      console.error(err)

      const authError = err as AuthApiError

      if (authError.message === 'Invalid login credentials') {
        setError({
          code: 'auth/invalid-credentials',
          message: t('wrong credentials'),
        })
      } else {
        setError({
          message:
            authError.status + ': ' + authError.message || t('unknown error'),
        })
      }

      setLoading(false)
    }
  }

  // Render nothing if we already have a Supabase user, waiting for redirect
  if (typeof user === 'undefined' || typeof token === 'undefined') {
    console.log(
      'Renders nothing on login page, since we have not set the user or token objects yet',
    )
    return null
  } else if (user !== null || token !== null) {
    console.log(
      'Renders nothing on login page, since we either have a logged in user or token',
    )

    return null
  }

  return (
    <>
      <Head>
        <title>
          {t('login')} &ndash; {t('common:app.name')}
        </title>
      </Head>

      <SiteHeader />

      <Menu>
        <MenuList>
          {user ? (
            <>
              <MenuListItem>
                <NavLink href='/programme'>
                  <FontAwesomeIcon icon={['fal', 'map']} />
                  Till programmet
                </NavLink>
              </MenuListItem>
              <MenuListItem>
                <NavLink href='/logout'>
                  <FontAwesomeIcon icon={['fal', 'sign-out']} />
                  Logga ut
                </NavLink>
              </MenuListItem>
            </>
          ) : null}
          <MenuListItem>
            <NavLink href='/upload'>
              <FontAwesomeIcon icon={['fal', 'tools']} />
              Kontrollpanel
            </NavLink>
          </MenuListItem>
        </MenuList>
        <MenuListFooter>
          <MenuListItem>
            <span>
              {t('common:app.name')} v{process.env.NEXT_PUBLIC_APP_VERSION}
            </span>
          </MenuListItem>
        </MenuListFooter>
      </Menu>

      {MessagesToRender}

      <LoginForm>
        <SiteLogo withIcon section={destination} />
        <h1>{loading ? t('verifying') : t('login')}</h1>
        {loading ? (
          <div className='spinner'>
            <FontAwesomeIcon icon={['far', 'spinner']} spin />
          </div>
        ) : (
          <form onSubmit={login}>
            {error ? <ErrorMessage>{error.message}</ErrorMessage> : null}
            <div className='input-group'>
              <label htmlFor='username' id='username-label'>
                {t('username')}
              </label>
              <input
                type='username'
                autoComplete='email'
                inputMode='email'
                id='username'
                name='username'
                placeholder={t('username placeholder')}
                aria-labelledby='username-label'
                required
              />
            </div>
            <div className='input-group'>
              <label htmlFor='password' id='password-label'>
                {t('password')}
              </label>
              <input
                type='password'
                autoComplete='current-password'
                id='password'
                name='password'
                placeholder={t('password placeholder')}
                aria-labelledby='password-label'
                required
              />
            </div>
            <button
              type='submit'
              className='btn btn-primary btn-big btn-wide'
              disabled={loading}
            >
              {t('login')}
            </button>
          </form>
        )}
        {(router.query.next as string) === '/bo' ? (
          <Protip style={{ marginTop: '3rem' }}>
            <strong>Observera!</strong>
            <p>
              Detta är inte kontrollpanelen, vilket du förmodligen ville nå, för
              att administrera din församling. Gå till{' '}
              <Link href='/upload'>Kontrollpanelen</Link>.
            </p>
          </Protip>
        ) : null}
      </LoginForm>
    </>
  )
}


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/login',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  