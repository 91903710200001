import styled from 'styled-components'

export const ErrorMessage = styled.article`
  padding: 1rem;

  color: white;
  background-color: #c33;

  margin-bottom: 2rem;

  border-radius: 0.5rem;
`
