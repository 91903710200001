import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import { IContactPerson } from '../../../declarations/interfaces'
import { StyledNote } from '../../../views/Upload/styles'

interface ContactPersonProps {
  contact: IContactPerson
  editContact(contact: string): void
  removeContact(): void
}

export default function ContactPerson({
  contact,
  // editContact,
  removeContact,
}: ContactPersonProps) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: contact.id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <StyledNote ref={setNodeRef} style={style} {...attributes}>
      <span className='drag-handle' {...listeners}>
        <FontAwesomeIcon icon='grip-vertical' />
      </span>
      <p className='text'>{contact.name}</p>
      <p className='text phone'>{contact.phone}</p>
      <button type='button' className='action-button' onClick={removeContact}>
        <FontAwesomeIcon icon='trash-alt' />
      </button>
    </StyledNote>
  )
}
