import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { StyledSiteHeader } from './styles'
import { useAuth } from '../../contexts/auth'
import { MenuButton } from '../dashboard/Header'
import SiteLogo from '../SiteLogo'
import { toggleMenu } from '../Menu/Menu'

interface SiteHeaderProps {
  showNav?: boolean
}

export default function SiteHeader({ showNav = true }: SiteHeaderProps) {
  const { t } = useTranslation('common')

  const { user } = useAuth()

  return (
    <StyledSiteHeader>
      <div className='container'>
        <MenuButton
          icon='bars'
          label={t('header:buttons.open menu')}
          title={t('header:buttons.open menu')}
          onClick={() => {
            toggleMenu()
          }}
          disabled={!toggleMenu}
          className='menu-toggle'
        />
        <SiteLogo />
        {showNav ? (
          <nav>
            <ul>
              {user ? (
                <>
                  <li>
                    <Link href='/programme'>
                      <a className='btn btn-primary'>
                        <FontAwesomeIcon icon={['fas', 'map']} />
                        Till programmet
                      </a>
                    </Link>
                  </li>
                </>
              ) : (
                <li>
                  <Link
                    href={
                      '/login?' +
                      new URLSearchParams({ next: '/programme' }).toString()
                    }
                  >
                    <a className='btn btn-primary'>
                      <FontAwesomeIcon icon={['far', 'sign-in']} />
                      Logga in
                    </a>
                  </Link>
                </li>
              )}
            </ul>
          </nav>
        ) : null}
      </div>
    </StyledSiteHeader>
  )
}
